<template>
  <div class="container about">
    <h1>About</h1>
    <p>This site is a collection of tools for calculating, converting, and querying data.</p>

    <h2>Why?</h2>
    <p>
      I often have a need to lookup DNS information or convert data to other formats.
      However, I disliked some of the sites available on the internet and how they worked.
      Creating my own tools site also allows me to build in features that I need or find useful.
    </p>
  </div>
</template>
